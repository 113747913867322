export const environment = {
    baseURL: 'https://api-pre-prod.crizac.com/v1/api/',
    fileBaseURL: 'https://crizac-assets.s3.ap-south-1.amazonaws.com/system',
    callBaseURL: 'https://www.crizac.com/yeasterdownload/',
    validatePassportURL: 'https://nps2entus1.execute-api.ap-south-1.amazonaws.com/default/ocr',
    currentLocationURL: 'https://ipapi.co/json/',
    basePHPURL: 'https://accounts.crizac.com/api_mongo/agent',
    paypalClientId: 'AeEifrmeT8xMflSOSZf6jFQMGMAVarQI_3LAnoPMgjQfITNyl1bBTBSUdYUyXjgysRc0Klp7Cgi71ZIu',
    apiPHPURLv2: 'https://accounts.crizac.com/api_mongo/',
    paymentPercentage: 10,
    tenant: "2",
    redirectionPassword: 'crizac123',
    agentPortallinkGA:'https://agent-dev-gaspl.crizac.com/',
};
